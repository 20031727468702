<template>
  <div>
    <el-row>
      <el-col>
        <div class="father">
          <div class="bg"></div>
          <div class="cover">成为我们的合作伙伴</div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="padding: 0 25%; background-color: #F8F8F8">
        <div style="margin-top: 100px;">
          <p style="text-align: left; font-size: 30px; font-weight: 500;">我们将为您提供...</p>
          <div class="top_content">
            <div class="content_item">
              <img :src="img_cooper" alt="合作" style="width: auto; height: auto; max-weight: 60px; max-height: 60px;">
              <p style="margin-top: 25px; font-weight: 500; font-size: 25px;">直接合作</p>
              <p style="margin-top: 25px;">您将直接与我们合作，以获得即时<br />
                技术支持和供应链支持</p>
            </div>
            <div class="content_item">
              <img :src="img_react" alt="react" style="width: auto; height: auto; max-weight: 60px; max-height: 60px;">
              <p style="margin-top: 25px; font-weight: 500; font-size: 25px;">直接合作</p>
              <p style="margin-top: 25px;">您将直接与我们合作，以获得即时<br />
                技术支持和供应链支持</p>
            </div>
            <div class="content_item">
              <img :src="img_teach" alt="teach" style="width: auto; height: auto; max-weight: 60px; max-height: 60px;">
              <p style="margin-top: 25px; font-weight: 500; font-size: 25px;">直接合作</p>
              <p style="margin-top: 25px;">您将直接与我们合作，以获得即时<br />
                技术支持和供应链支持</p>
            </div>
          </div>
        </div>
        <div>
          <p style="font-weight: 500; font-size: 45px; margin-top: 200px;">成为合作伙伴</p>
          <div class="btm_content" style="margin-top: 60px;">
            <div class="btm_item">
              <img :src="img_edit" alt="edit" class="img_resize">
              <p style="font-weight: 500; font-size: 20px; margin-top: 10px;">提交申请</p>
              <div class="btm_num">1</div>
              <div class="btm_info">填写并提交申请表</div>
            </div>
            <div class="btm_item">
              <img :src="img_info" alt="edit" class="img_resize">
              <p style="font-weight: 500; font-size: 20px; margin-top: 10px;">信息确认</p>
              <div class="btm_num">2</div>
              <div class="btm_info">我们将确认您的信息</div>
            </div>
            <div class="btm_item">
              <img :src="img_talk" alt="edit" class="img_resize">
              <p style="font-weight: 500; font-size: 20px; margin-top: 10px;">联络洽谈</p>
              <div class="btm_num">3</div>
              <div class="btm_info">我们将与符合条件的
                申请伙伴联络，讨论
                具体合作事宜</div>
            </div>
            <div class="btm_item">
              <img :src="img_text" alt="edit" class="img_resize">
              <p style="font-weight: 500; font-size: 20px; margin-top: 10px;">签约</p>
              <div class="btm_num">4</div>
              <div class="btm_info">双方确认合作条款与
                细节，正式签约</div>
            </div>
            <div class="btm_item">
              <router-link to="/collection">
                <img :src="img_hand" alt="edit" class="img_resize">
              </router-link>
              <p style="font-weight: 500; font-size: 20px; margin-top: 10px;">达成合作</p>
              <div class="btm_num">5</div>
              <div class="btm_info">成为正式合作伙伴后
                我们将有专人与您长
                期对接</div>
            </div>
          </div>
        </div>
        <div style="height: 500px; margin-top: 250px;">
          <img :src="img_erwei" alt="二维码" style="weight: auto; height: auto; max-weight: 100px;">
          <p style="font-weight: 500; font-size: 35px;">CHIGEE公众号</p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="border-bottom: 1px solid #000000"></el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img_edit: require('../../../public/images/tolealer/edit.png'),
      img_info: require('../../../public/images/tolealer/info.png'),
      img_talk: require('../../../public/images/tolealer/talk.png'),
      img_text: require('../../../public/images/tolealer/text.png'),
      img_hand: require('../../../public/images/tolealer/hand.png'),
      img_erwei: require('../../../public/images/tolealer/erwei.png'),
      img_cooper: require('../../../public/images/tolealer/cooper.png'),
      img_react: require('../../../public/images/tolealer/react.png'),
      img_teach: require('../../../public/images/tolealer/teach.png')
    }
  }
}
</script>
<style lang="less" scoped>
.father {
  position: relative;
}
.bg {
  width: 100%;
  height: 500px;
  background: url('../../../public/images/tolealer/bg.png') no-repeat;
  background-size: 100% 100%;
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 500px;
  line-height: 360px;
  color: #fff;
  font-weight: 500;
  font-size: 60px;
  background-color: rgba(0, 0, 0, 0.2);
}
.top_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  height: 360px;
  .content_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 360px;
    background: #fff;
    border-radius: 20px;
  }
}
.btm_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
  // background-color: purple;
  .btm_item {
    // background: pink;
    position: relative;
    width: 20%;
    height: 140px;
    border-bottom: 1px solid #111;
    .btm_num {
      position: absolute;
      bottom: 0%;
      left: 50%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #111;
      transform: translate(-50%, 10px);
      color: #fff;
    }
    .btm_info {
      position: absolute;
      bottom: 0%;
      left: 50%;
      width: 150px;
      height: 40px;
      // border-radius: 50%;
      // background-color: #111;
      transform: translate(-50%, 80px);
      color: grey;
      // background: purple;
    }
  }
}
.img_resize {
  width: auto;
  height: auto;
  max-width: 60px;
  max-height: 60px;
}
</style>